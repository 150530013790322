import { render, staticRenderFns } from "./CartWidget.vue?vue&type=template&id=b53a0f74&scoped=true&"
import script from "./CartWidget.vue?vue&type=script&lang=ts&"
export * from "./CartWidget.vue?vue&type=script&lang=ts&"
import style0 from "../assets/styles/CartWidget.scss?vue&type=style&index=0&id=b53a0f74&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b53a0f74",
  null
  
)

export default component.exports