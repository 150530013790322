



















































import { Component, Prop } from 'vue-property-decorator';
import VueBaseWidget from '@/utils/widgets/VueBaseWidget';
import { mixins } from 'vue-class-component';
import VueRegisterStoreWidget from '@/utils/widgets/VueRegisterStoreWidget';
import SalesPackage from '@/models/graphql/SalesPackage';
import ButtonComponent from '@/components/ButtonComponent.vue';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';
import HandoutEditModal from '@/components/modals/HandoutEditModal.vue';
import { namespace } from 'vuex-class';
import { BasicTypes } from '@/utils/types/BasicTypes';
import Cart from '@/models/LocalStorage/Cart';
import PackageInformation from '@/models/LocalStorage/PackageInformation';
import PillComponent from '@/components/pill/PillComponent.vue';
import Variant from '@/utils/enums/Variant';
import CheckoutOrderSummaryComponent from '@/components/checkout/CheckoutOrderSummaryComponent.vue';
import PackageListComponent from '@/components/PackageListComponent.vue';
import FormatHelper from '@/utils/helpers/FormatHelper';
import ButtonIconComponent from '@/components/ButtonIconComponent.vue';

const widgetDispatcherStore = namespace('WidgetDispatcherStore');
const salesPackageStore = namespace('SalesPackageStore');
const orderStore = namespace('OrderStore');
const localStorageCartStore = namespace('LocalStorageCartStore');

@Component({
  components: {
    ButtonIconComponent,
    HandoutEditModal,
    FontAwesomeComponent,
    ButtonComponent,
    PackageListComponent,
    PillComponent,
    CheckoutOrderSummaryComponent,
  },
})
export default class CartWidget extends mixins(VueBaseWidget, VueRegisterStoreWidget) {
  @Prop({
    required: false,
    default: null,
  })
  private entityCode!: string;

  @widgetDispatcherStore.Getter
  private fetchMagicArgs!: Record<string, BasicTypes>;

  @salesPackageStore.Action
  private getCartInformation!: (payload: {
    localStoragePackage: Array<PackageInformation>;
    companyUid: string;
    }) => Promise<boolean>;

  @salesPackageStore.Mutation
  private deletePackage!: (uids: string) => void;

  @salesPackageStore.Getter
  private salesPackages!: SalesPackage[];

  @orderStore.Action
  private createOrderAndPaymentIntent!: (payload: {
    companyUid: string;
    salesPackages: Array<PackageInformation>;
    userUid: string;
    needsApproval: boolean;
  }) => Promise<string>;

  @localStorageCartStore.Getter
  private currentCartData!: Cart;

  @localStorageCartStore.Action
  private setCart!: (cartUid: string) => void;

  @localStorageCartStore.Action
  private deletePackages!: (packageUid: string) => void;

  @localStorageCartStore.Action
  private increaseCart!: (payload: {packageUid: string; value: number}) => void;

  @localStorageCartStore.Action
  private resetCart!: (cartUid: string) => void;

  private Variant = Variant;

  private loadingCheckout = false;

  private loadingRequestApproval = false;

  private get uid(): string | null {
    let r = this.entityCode;
    if (this.entityCode) {
      const matches = this.entityCode.match(/(%[a-zA-Z-_]+%)/gs);
      if (matches) {
        matches.forEach((m) => {
          const prop = m.replaceAll('%', '').trim();
          r = r.replaceAll(m, this.$route.params[prop]);
        });
        return r;
      }
    }
    return this.entityCode;
  }

  private get totalValue(): string {
    const value = this.salesPackages.reduce((acc, el) => acc + ((el.price
      && el.localyOrderQuantity) ? el.price * el.localyOrderQuantity : 0), 0);
    return FormatHelper.formatCurrency(value / 100);
  }

  private get orderQuantityToHigh(): boolean {
    return this.salesPackages.some((item: SalesPackage) => item.isOverStock);
  }

  private get isCompanySet(): boolean {
    return !!(this.fetchMagicArgs.companyId);
  }

  private get canOrder(): boolean {
    return this.isCompanySet && !this.orderQuantityToHigh && this.salesPackages.length > 0;
  }

  private get needsApproval(): boolean {
    return this.salesPackages.some((obj: SalesPackage) => obj.needsApproval === true);
  }

  created(): void {
    this.setDataConfig();
  }

  mounted(): void{
    if (this.fetchMagicArgs.companyId) {
      this.setCart(this.fetchMagicArgs.companyId.toString());
      this.updateCartInformation();
    }
  }

  private updateCartInformation(): void {
    this.getCartInformation({
      localStoragePackage: this.currentCartData.packagesInformation,
      companyUid: this.fetchMagicArgs.companyId as string || '',
    });
  }

  private deleteSalePackage(item: SalesPackage): void {
    this.deletePackage(item.uid);
    if (this.uid) {
      this.deletePackages(item.uid);
    }
  }

  private quantity(payload: {item: SalesPackage; num: number}): void {
    if (!payload.item.localyOrderQuantity || !this.uid) return;
    if (payload.num === 0 && payload.item.localyOrderQuantity > 0) {
      payload.item.localyOrderQuantity -= 1;
    } else if (payload.num !== 0) {
      payload.item.localyOrderQuantity += 1;
    }
    if (payload.item.localyOrderQuantity === 0) {
      this.deleteSalePackage(payload.item);
    } else {
      this.increaseCart({
        packageUid: payload.item.uid,
        value: payload.item.localyOrderQuantity,
      });
    }
  }

  private checkoutProcess(): void {
    if (this.uid === null) return;
    this.loadingCheckout = true;
    this.getCartInformation({
      localStoragePackage: this.currentCartData.packagesInformation,
      companyUid: this.uid,
    }).then((isQuantityAvaileble: boolean) => {
      if (isQuantityAvaileble && this.uid) {
        this.createOrderAndPaymentIntent({
          companyUid: this.uid,
          salesPackages: this.currentCartData.packagesInformation,
          userUid: this.authUser.uid,
          needsApproval: false,
        }).then((orderUid: string) => {
          if (this.uid) {
            this.$router.push({ name: 'company-cms-checkout', params: { companyId: this.uid, orderId: orderUid } });
          }
        });
      }
    });
  }
}
